import { Shelf } from "../domain/Place";

export const MockData: Shelf[] = [
  {
    id: "001",
    name: "R1A1",

    quantity: 2,
  },
  {
    id: "002",
    name: "R1A2",

    quantity: 3,
  },
  {
    id: "003",
    name: "R1A3",

    quantity: 4,
  },
  {
    id: "004",
    name: "R1B1",

    quantity: 7,
  },
  {
    id: "005",
    name: "R1B2",

    quantity: 1,
  },
  {
    id: "006",
    name: "R1B3",
    quantity: 0,
  },
];
