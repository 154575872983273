import styled from "styled-components";

export const TableStyles = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 30px;
  font-size: 36px;

  h1 {
    padding-bottom: 10px;
  }

  table {
    border-spacing: 0;
    border: 1px solid #cac5d74a;
    overflow: scroll;
    width: 100%;
    table-layout: auto;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cac5d74a;
      border-right: 1px solid #cac5d74a;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
        width: 100%;
      }
    }
  }

  tr:nth-of-type(2n) {
    background-color: #f7f7f7;

    input {
      background-color: #f7f7f7;
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  thead {
    background-color: #175386;
    color: white;
  }

  tbody tr:hover {
    background-color: #e6e6e6;

    input {
      background-color: #e6e6e6;
    }
  }
  button {
    margin-bottom: 10px;
  }
`;
