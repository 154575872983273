import React, { useEffect } from "react";

import "./App.css";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import store from "./store/store";
import { observer } from "mobx-react";
import { Table } from "./table";

function App() {
  const initialize = () => {
    store.init();
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="App">
      <h1>{store.device?.address}</h1>
      <section style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {!store.device ? (
          <div>
            <h2>Zeskanuj kod QR</h2>
            <img alt="QrCode" src={`data:image/png;base64,${store.getConnectionQrCode()}`} />
          </div>
        ) : (
          <div>
            <h2>Połączono</h2>
          </div>
        )}
      </section>
      <Table />

      <ToastContainer />
    </div>
  );
}

export default observer(App);
