import { makeAutoObservable } from "mobx";
import { BeeperBurst, ButtonEvent, LedBurst, NIMMSTA, NimmstaConnectionManager, NimmstaDevice, VibratorBurst } from "nimmsta-web-library";
import { toast } from "react-toastify";
import { correctRequest, correctView, elementInApp, initLayout, notFound, saved } from "../layout/NimmstaLayouts";
import { MockData } from "../Mock/data";

NIMMSTA.websocketPort = 64693;
NIMMSTA.requestSendTimeout = 1000;
NIMMSTA.maxCommunicationRetries = 3;
NIMMSTA.keepAlwaysConnected = true;
const TIMEOUT = 250;
const handleNotify = () => toast("Podane miejsce magazynowe nie istnieje");

const LED_repeat = 3; // Amount of repeats, 0 means indefinitely. Must be greater or equal to 0 and smaller or equal to 255
const LED_duration = 200; // Duration how long one cycle takes in milliseconds. Must be greater or equal to 10 and smaller or equal to 4095
const LED_pulseDuration = 250;
const successColor = () => {
  const red = 0;
  const green = 255;
  const blue = 0;
  return {
    red,
    green,
    blue,
  };
};
const errorColor = () => {
  const red = 255;
  const green = 0;
  const blue = 0;
  return {
    red,
    green,
    blue,
  };
};
const succesLedBurst = () => {
  const color = successColor();
  return new LedBurst(LED_repeat, LED_duration, LED_pulseDuration, color.red, color.green, color.blue);
};
const errorLedBurst = () => {
  const color = errorColor();
  return new LedBurst(LED_repeat, LED_duration, LED_pulseDuration, color.red, color.green, color.blue);
};
const succesBeeperBurst = () => {
  return new BeeperBurst(1, 250, 250, 50);
};
const errorBeeperBurst = () => {
  return new BeeperBurst(3, 250, 400, 80);
};
const succesvibrationBurst = () => {
  return new VibratorBurst(1, 250, 250, 50);
};
const errorVibrationBurst = () => {
  return new VibratorBurst(3, 250, 400, 80);
};
class Store {
  scanned: string = "";
  data = MockData;
  realQuantity = 0;
  connectionManager = new NimmstaConnectionManager();
  connectionCode: any;
  device?: NimmstaDevice;

  async init() {
    NIMMSTA.onReady(async () => {
      try {
        const codeRequesr = await this.connectionManager.getConnectionCode();
        this.setConnectionCode(codeRequesr);
        const device = await this.connectionManager.connectToConnectionCode(codeRequesr.connectionCode);
        device.scanEvent.subscribe((event) => {
          this.handleInputPlaces(event.barcode);
        });
        device.buttonEvent.subscribe((e) => this.handleOnClick(e));
        await device.setXMLLayout(initLayout);
        this.setDevice(device);
      } catch (e) {
        console.log(e);
      }
    });
  }
  constructor() {
    makeAutoObservable(this);
  }

  private setDevice(device: NimmstaDevice) {
    this.device = device;
  }

  private setConnectionCode(connectionCodeResponse: any) {
    this.connectionCode = connectionCodeResponse;
  }

  setScanned(value: string) {
    this.scanned = value;
  }

  setRealQuantity(quantity: number) {
    this.realQuantity = quantity;
  }
  clear() {
    this.scanned = "";
    this.realQuantity = 0;
  }

  incrementBy(value: number) {
    this.realQuantity += value;
  }
  decrementBy(value: number) {
    this.realQuantity -= value;
    if (this.realQuantity < 0) {
      this.realQuantity = 0;
    }
  }
  getConnectionQrCode() {
    return this.connectionCode ? this.connectionCode.qrCodeImage : "";
  }

  handleInputPlaces = async (value: string) => {
    const finded = this.data.find((x) => x.id === value);
    if (!finded) {
      handleNotify();
      this.clear();

      await this.device?.setXMLLayout(notFound);
      this.device?.triggerLEDBurst(errorLedBurst());
      this.device?.triggerBeeperBurst(errorBeeperBurst());
      this.device?.triggerVibratorBurst(errorVibrationBurst());
      setTimeout(async () => {
        await this.device?.setXMLLayout(initLayout);
      }, TIMEOUT);
    } else {
      this.setScanned(value);
      this.setRealQuantity(finded.quantity);
      await this.device?.setXMLLayout(elementInApp);
      this.device?.triggerLEDBurst(succesLedBurst());
      this.device?.triggerBeeperBurst(succesBeeperBurst());
      this.device?.triggerVibratorBurst(succesvibrationBurst());
      setTimeout(async () => {
        await this.device?.setXMLLayout(correctRequest(finded));
      }, TIMEOUT);
    }
  };
  save() {
    this.data = this.data.map((x) => {
      if (x.id === this.scanned) {
        return {
          ...x,
          quantity: this.realQuantity,
        };
      }
      return x;
    });
  }
  handleOnClick = async (event: ButtonEvent) => {
    console.log(`Button "${event.value}" was pressed.`);
    switch (event.name) {
      case "yes":
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "no":
        this.device?.setXMLLayout(initLayout);
        break;

      case "increment1":
        this.incrementBy(1);
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "increment5":
        this.incrementBy(5);
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "increment10":
        this.incrementBy(10);
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "decrement1":
        this.decrementBy(1);
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "decrement5":
        this.decrementBy(5);
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "decrement10":
        this.decrementBy(10);
        this.device?.setXMLLayout(correctView(this.realQuantity));
        break;
      case "save":
        this.save();
        this.device?.setXMLLayout(saved);
        this.device?.triggerLEDBurst(succesLedBurst());
        this.device?.triggerBeeperBurst(succesBeeperBurst());
        this.device?.triggerVibratorBurst(succesvibrationBurst());
        setTimeout(async () => {
          this.device?.setXMLLayout(initLayout);
        }, TIMEOUT);
        break;
    }
  };
}
const store = new Store();
export default store;
