import { Shelf } from "../domain/Place";

export const initLayout = `<?xml version="1.0" encoding="utf-8"?>
<NimmstaLayout name="One Cell Layout with Heading">
    <device width="1.54" height="1.54" pxx="200" pxy="200">
        <screen default="true" name="default">
            <staticElements>
                <statusbar />
                <cell x="3" horizontalAlignment="center" fontSize="30pt" name="heading">Si4 APP</cell>
                <horizontalLine y="70"></horizontalLine>
                <cell x="3" y="65" horizontalAlignment="center" fontSize="17pt" wrapMode="wrap" maxLines="0" name="text">
MAGAZYN CZESCI ZAMIENNYCH</cell>
<cell x="3" y="75" horizontalAlignment="center" fontSize="26pt" fontfamiy"Bahnschrift_SemiBold" wrapMode="wrap" maxLines="0" name="text">

SKANUJ KOD</cell>

            </staticElements>
        </screen>
    </device>
</NimmstaLayout>
`;

export const elementInApp = `<?xml version="1.0" encoding="utf-8"?>
<NimmstaLayout name="Success Layout">
    <device width="1.54" height="1.54" pxx="200" pxy="200">
        <screen default="true" name="default">
            <staticElements>
                <statusbar/>
                <icon horizontalAlignment="center" y="50" name="success_tick" align="c" />
                <cell horizontalAlignment="center" fontSize="17" wrapMode="wrap" maxLines="0" name="text">
Pozycja w bazie</cell>
            </staticElements>
        </screen>
    </device>
</NimmstaLayout>`;

export const notFound = `<?xml version="1.0" encoding="utf-8"?>
<NimmstaLayout name="Error Layout">
    <device width="1.54" height="1.54" pxx="200" pxy="200">
        <screen default="true" name="default">
            <staticElements>
                <statusbar/>
                <icon horizontalAlignment="center" y="50" name="error_cross" align="c" />
                <cell horizontalAlignment="center" fontSize="17" wrapMode="wrap" maxLines="0" name="text">Brak pozycji w bazie!</cell>
            </staticElements>
        </screen>
    </device>
</NimmstaLayout>`;

export const correctRequest = (shelf: Shelf) => `<?xml version="1.0" encoding="utf-8"?>
<NimmstaLayout name="Counter Layout">
    <device width="1.54" height="1.54" pxx="200" pxy="200">
        <screen default="true" name="default">
            <staticElements>
                <statusbar />
		<cell x="15" y="30" horizontalAlignment="left" fontSize="17pt" name="heading">Miejsce</cell>
               	<cell x="100" y="30" width="70" horizontalAlignment="right" fontSize="17pt" name="anzahl">${shelf.name}</cell>
                <cell x="15" y="55" horizontalAlignment="left" fontSize="17pt">ILOSC:</cell>
                <cell x="100" y="55" width="70" horizontalAlignment="right" fontSize="17pt" name="anzahl">${shelf.quantity}</cell>
		<cell x="15" y="90" horizontalAlignment="center" fontSize="17pt" name="heading">Zmienic stan?</cell>
                <button x="6" y="120" type="INVERTED_ROUNDED" horizontalAlignment="center" width="90" height="70" name="yes" fontSize="26pt">TAK</button>
                <button x="104" y="120" type="INVERTED_ROUNDED" horizontalAlignment="center" width="90" height="70" name="no" fontSize="26pt">NIE</button>
            </staticElements>
        </screen>
    </device>
</NimmstaLayout>`;

export const correctView = (accual: number) => `<?xml version="1.0" encoding="utf-8"?>
<NimmstaLayout name="Counter Layout">
    <device width="1.54" height="1.54" pxx="200" pxy="200">
        <screen default="true" name="default">
            <staticElements>
                <statusbar />
                <cell x="15" y="40" horizontalAlignment="left" fontSize="30pt">ILOSC:</cell>
                <cell x="100" y="40" width="70" horizontalAlignment="right" fontSize="30pt" name="anzahl">${accual}</cell>
                <button x="0" y="100" type="plain" horizontalAlignment="center" width="65" height="50" name="increment1">+ 1</button>
                <button x="67" y="100" type="plain" horizontalAlignment="center" width="65" height="50" name="increment5">+ 5</button>
                <button x="134" y="100" type="plain" horizontalAlignment="center" width="65" height="50" name="increment10">+ 10</button>
                <button x="0" y="150" type="plain" horizontalAlignment="center" width="65" height="50" name="decrement1">- 1</button>
                <button x="67" y="150" type="plain" horizontalAlignment="center" width="65" height="50" name="decrement5">- 5</button>
                <button x="134" y="150" type="plain" horizontalAlignment="center" width="65" height="50" name="decrement10">- 10</button>
<button x="0" y="0" type="plain" horizontalAlignment="center" width="200" height="110" name="save"></button>
            </staticElements>
        </screen>
    </device>
</NimmstaLayout>`;

export const saved = `<?xml version="1.0" encoding="utf-8"?>
<NimmstaLayout name="Success Layout">
    <device width="1.54" height="1.54" pxx="200" pxy="200">
        <screen default="true" name="default">
            <staticElements>
                <statusbar/>
                <icon horizontalAlignment="center" y="30" name="success_tick" align="c" />
                <cell horizontalAlignment="center" fontSize="26" wrapMode="wrap" maxLines="0" name="text">
KOREKTA ZAPISANA</cell>
            </staticElements>
        </screen>
    </device>
</NimmstaLayout>`;
